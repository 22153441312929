/*** Footer ***/
#footer{
    background-color: var(--secondary-color);
    color: #fff;
}
footer #sub-footer{
    padding: 1rem 0;
    background-color: #52d3b7;
    box-shadow: 0 -1em 1em -1em rgb(0 0 0 / 20%);
}
footer #sub-footer .sub-footer--inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
footer #sub-footer .sub-footer--inner ul{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
footer #sub-footer .sub-footer--inner ul li{
    font-size: 1.4rem;
    padding: 0 0.5rem;
    margin: 10px 0;
}
footer #sub-footer .sub-footer--inner ul li:after{
    content: "|";
    padding-left: .5rem;
}
footer #sub-footer .sub-footer--inner ul li:last-child:after{
    content: none;
}
@media (max-width: 768px){
    footer #sub-footer .sub-footer--inner{
        flex-direction: column-reverse;
    }
}
footer .main-row{
    padding: 4rem 0;
}
footer .main-row>[class*='col-']{
    padding-bottom: 4rem;
}
footer .footer-section{
    padding: 2rem;
    min-height: 100%;
    background-color: gray;
    background-color: #5f5252;
}
footer .footer-section .footer-title{
    border-bottom: 3px solid var(--primary-color);
    margin-top: 0;
    font-size: 3rem;
    line-height: 1.2;
}
footer .footer-section .footer-body{
    font: 1.5rem/120% 'slinglight', Arial, sans-serif;
    padding-top: 1rem;
}
footer .footer-section .footer-body .footer-inner{padding: 0 1rem;}
footer .footer-section .footer-body .footer-inner a{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
footer .footer-section .footer-body .footer-inner a i{margin-right: 1.5rem}
footer .footer-section .footer-body a{
    display: block;
    padding: .8rem 0;
}
/*footer .footer-section .footer-body a:hover, footer .footer-section .footer-body h6:hover{*/
footer .footer-section .footer-body a:hover{
    color: rgb(88, 196, 167);
    text-decoration: none;
}
footer .footer-section .footer-body .hr{ margin: 1rem 2rem 1.5rem 2rem;}
footer .footer-section .footer-body h6{
    font-size: 1.4rem;
    -webkit-transition: all .35s ease-out;
    -moz-transition: all .35s ease-out;
    -o-transition: all .35s ease-out;
    transition: all .35s ease-out;
    
}
footer .footer-section .footer-body h6:hover{cursor: default;}
footer .footer-section .footer-body .highlighted{
    margin: 0;
    display: inline-block;
}
footer #sub-footer .content, #footer .content a{
    display: inline-block;
    font-size: 1.4rem;
}
footer #sub-footer .content{padding:0 1rem;}

footer .footer-section #aforisma-footer{
    margin-top: 6.5rem;
}
footer .footer-section #aforisma-footer blockquote{
    padding-top: 0;
    padding-bottom: 0px;
}
footer .footer-section #aforisma-footer .aforisma{
    font-size: 28px;
    margin: 0;
    font-family: 'Niconne', cursive;
    line-height: 1;
}
footer .footer-section #aforisma-footer .aforisma-author{
    font-family: 'slinglight';
}

footer .main-row{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;    

    -webkit-box-align-content: center;
    -webkit-align-content: center;      
    -ms-flex-align-content: center;
    align-content: center;

    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    -moz-box-pack: space-between;
    justify-content: space-between;

    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    flex-flow: row wrap;
}
footer .main-row > .footer-element{
    flex-grow: 0;
    padding: 0 15px;
}

@media (min-width: 768px){
    footer .main-row > .footer-element{flex-basis: 33.33333333%;}
}
@media (max-width: 767px){
    footer .main-row > .footer-element{flex-basis: 100%;}
}
@media (min-width: 768px) and (max-width: 991px){
    .footer-container{width: auto;}
}
/* @media (min-width: 768px){
    footer .lightgallery .col-lg-4:nth-child(-n+3)  .figure-container, footer .lightgallery .col-md-4:nth-child(-n+3)  .figure-container{margin-top: 0;}
    footer .lightgallery .col-lg-4:nth-last-child(-n+3)  .figure-container, footer .lightgallery .col-md-4:nth-last-child(-n+3)  .figure-container{margin-bottom: 0;}
} */
@media (max-width: 767px){
    footer .main-row > .footer-element{
        flex-basis: 100%;
    }
    footer .lightgallery .col-sm-6:nth-child(-n+2)  .figure-container, footer .lightgallery .col-xs-6:nth-child(-n+2)  .figure-container{margin-top: 0;}
    /* footer .lightgallery .col-sm-6:nth-last-child(-n+2)  .figure-container, footer .lightgallery .col-xs-6:nth-last-child(-n+2)  .figure-container{margin-bottom: 0;} */
}