/*** Navigation bar ***/
header #navigation-bar{
    background: #ffffff;
    background: rgba(255, 255, 255, 0.95);
    padding: 2rem 0;
    border-radius: unset;
}
header .navbar {
    width: 100%;
    z-index: 1000;
    background: transparent;
    margin-bottom: 0;
}
#primary-navbar{border: none;}
#primary-navbar #main-menu-nav{
    position: relative;
    padding: 0;
}

#primary-navbar #top-search{
    display: block;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 10;
}

#primary-navbar #top-search .navbar-search .search-input {
    font-size: 1.6rem;
    line-height: 1.4;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}
#primary-navbar #top-search .navbar-search .search-submit, #primary-navbar .search_box-toggler {
    line-height: 100%;
    font-size: 1.8rem;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#primary-navbar #top-search .navbar-search .search-submit, #primary-navbar .search_box-toggler.toggle_in {color: #00030a;}
#primary-navbar #top-search .navbar-search .search-submit:hover, #primary-navbar .search_box-toggler.toggler_in:hover {color: var(--primary-color);}
#primary-navbar .search_box-toggler.toggler_in { color: #333; }
#primary-navbar .search_box-toggler:focus { outline: 0;}
#primary-navbar .search_box-toggler.toggler_out{ 
    left: 1px;
    top: 1px;
    height: 46px;
    padding: 1rem 1.5rem;

    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 1px;
    border-color: var(--primary-color);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
#primary-navbar:before{
    display: block;
    content: unset;
}
.primary-nav {
    margin: 0;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    font-family: 'slinglight', Arial, sans-serif;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    float: none;
}
.primary-nav .dropdown{position:relative}
.primary-nav li{float:none;}
.primary-nav li.menu-item-type-custom a:not([href]){cursor: default}
.primary-nav li.menu-item{
    padding: 1rem 2rem;
}
.primary-nav li.menu-item a{
    text-transform: uppercase;
    color: #00030a;
    color: #333;
    letter-spacing: .2rem;
    padding: .5rem 0;
}
.primary-nav li.menu-item.active a, .primary-nav li.menu-item.active a:hover{
    color: #00030a;
    color: #333;
    background: transparent;
}

.primary-nav > li.menu-item a:after{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 3px;
    max-width: 100%;
    background: var(--primary-color);
    -webkit-transition: all .35s ease-out;
    -moz-transition: all .35s ease-out;
    -o-transition: all .35s ease-out;
    transition: all .35s ease-out;

}

.primary-nav > li.menu-item.active a:after, .primary-nav > li.menu-item.focus a:after, .primary-nav > li.menu-item a:hover:after{
    opacity: 1;
    visibility: visible;
    width: 100%;
}

@media (min-width: 992px){
    #primary-navbar #top-search{
        position: absolute;
        top: 0;
        right: 0;
        display: none;
    }

    #primary-navbar #top-search .navbar-search .search-input {
        border-color: var(--primary-color);
        padding-left: 55px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .primary-nav {
        position: relative;
        display: flex;
        align-items: center;
        justify-items: flex-end;
        margin: 0 5rem 0 0;
    }

    #primary-navbar #main-menu-nav{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .primary-nav li.menu-item a{
        text-align: center;
    }
}
@media (max-width: 991px ){
    header .navbar {min-height: 48px; }
    #primary-navbar #main-menu-nav{
        position:fixed;
        z-index: 9000;
        background: #fff;
        width: 100%;
        height: 100%;
        /*top: 49px;*/
        top: 58px;
        right: -100%;
        -webkit-transition: all .8s ease-in-out;
        -moz-transition: all .8s ease-in-out;
        -o-transition: all .8s ease-in-out;
        transition: all .8s ease-in-out;
        border: 1px solid #e5e7e9;
    }
    #primary-navbar #main-menu-nav.opened{
        right: 0;
    }

    #primary-navbar #top-search{
        display: block;
        padding: 2rem 1.5rem;
    }
    #primary-navbar #top-search .navbar-search .search-input {
        font-size: 1.5rem;
        line-height: 1.4;
    }
    #primary-navbar #top-search .navbar-search .search-submit {
        font-size: 1.8rem;
    }

    #primary-navbar .primary-nav > li.menu-item{border-bottom: 1px solid #e5e7e9;}
    #primary-navbar .primary-nav > li.menu-item.active{background: var(--primary-color);}
    #primary-navbar .primary-nav > li.menu-item.active a{color: #fff;}

    #primary-navbar #main-menu-nav .contacts, #primary-navbar #main-menu-nav .contacts a{ width:100%; }
    #primary-navbar #main-menu-nav .contacts{
        position: fixed;
        bottom: 0;
    }
    #primary-navbar #main-menu-nav .contacts a{
        font-size: 1.6rem   
    }
}

@media (max-width: 767px ){
    header #navigation-bar {
        padding: 0;
        border: none;
        border-bottom: 1px solid #e5e7e9;
        position: fixed;
    }
}

/*** Hamburger Icon ***/
.hamburger-toggle{
    background: transparent;
    position: absolute;
    top: 0;
    z-index: 9999;
    right: 0;
}

header .sub-header .hamburger-toggle{
    position: relative;
}

.hamburger-toggle .hamburger-icon{
    float: none;
    background: transparent;
    border: none;
    display: block;
    height: 32px;
    margin: 8px 15px;
    width: 32px;
    z-index: 999;
}
.hamburger-toggle .hamburger-icon:hover, .hamburger-toggle .hamburger-icon:focus, .hamburger-toggle .hamburger-icon:active {
    outline: none;
    background-color: transparent;
}
.hamburger-toggle .hamburger-icon:before{
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);                      
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;
    color: var(--primary-color);
    content: '';
    display: block;
    font-size: 14px;
    line-height: 32px;
    opacity: 0;
    text-align: center;
    width: 110px;
}         
.hamburger-toggle .hamburger-icon .hamb-top, .hamburger-toggle .hamburger-icon .hamb-middle, .hamburger-toggle .hamburger-icon .hamb-bottom  {
    height: 4px;
    left: 0;
    position: absolute;
    width: 100%;
    background-color: var(--primary-color);
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;
}
.hamburger-toggle .hamburger-icon .hamb-top{top: 5px;}
.hamburger-toggle .hamburger-icon .hamb-middle {
    margin-top: -2px;
    top: 50%;
}
.hamburger-toggle .hamburger-icon .hamb-bottom {bottom: 5px;}
.hamburger-toggle .hamburger-icon.opened .hamb-middle {display: none;}
.hamburger-toggle .hamburger-icon.opened .hamb-top, .hamburger-toggle .hamburger-icon.opened .hamb-bottom {
    transform: .2s cubic-bezier(.73,1,.28,.08);
    margin-top: -2px;
    top: 50%;
}
.hamburger-toggle .hamburger-icon.opened .hamb-top{
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hamburger-toggle .hamburger-icon.opened .hamb-bottom{
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

@media (min-width: 768px) and (max-width: 991px){
    body.fixed_top #primary-navbar .hamburger-toggle{display:block!important;}
}